<template>
  <article class="thread-teaser" data-test-id="thread-teaser">
    <div class="thread-teaser__content" data-test-id="thread-teaser-content">
      <div class="thread-teaser__status">
        <span
          v-if="thread.isFeatured"
          class="thread-teaser__featured"
          data-test-id="thread-teaser-featured"
        >Featured</span>
        <recent-activity-time
          v-if="thread.lastActiveAt"
          class="thread-teaser__activity"
          data-test-id="thread-teaser-activity"
          :date="thread.lastActiveAt"
        />
      </div>
      <component
        :is="makeHeadingH3 ? 'h3' : 'h2'"
        class="thread-teaser__heading heading--3"
        data-test-id="thread-teaser-heading"
      >
        <router-link
          :to="{ name: 'forumThread', params: { slug: thread.slug } }"
          class="thread-teaser__heading-link link link--unstyled"
          data-test-id="thread-teaser-heading-link"
          v-html="thread.name"
        />
      </component>
      <p class="thread-teaser__excerpt" data-test-id="thread-teaser-excerpt">
        <router-link
          :to="{ name: 'forumThread', params: { slug: thread.slug } }"
          class="thread-teaser__excerpt-link link link--unstyled"
        >
          <span v-html="summary" />
        </router-link>
      </p>
      <div class="thread-teaser__actions">
        <bookmark-button v-if="showBookmarkButton" :bookmarkable="thread" />
      </div>
      <engagement-bar
        v-if="displayOptions.showEngagement"
        object-type="thread"
        :the-object="thread"
        :comment-count="thread.commentCount"
      />
      <tag-list v-if="thread.tags && thread.tags.length > 0" :tags="thread.tags" />
    </div>
  </article>
</template>

<script>
import EngagementBar from '@/components/Engagement/EngagementBar.vue';
import TagList from '@/components/Forum/TagList.vue';
import RecentActivityTime from '@/components/Global/RecentActivityTime.vue';
import BookmarkButton from '@/components/Bookmark/BookmarkButton.vue';

export default {
  name: 'ThreadTeaser',
  components: {
    EngagementBar,
    TagList,
    RecentActivityTime,
    BookmarkButton,
  },
  props: {
    thread: {
      type: Object,
      required: true,
    },
    showBookmarkButton: {
      type: Boolean,
      default: false,
    },
    makeHeadingH3: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayOptions() {
      const defaults = {
        showEngagement: true,
      };
      if (this.thread.options) {
        return { ...defaults, ...this.thread.options };
      }
      return defaults;
    },
    showEllipsis() {
      const lastThreeCharsOfThreadBody = this.thread.body.slice(-3);
      if (!this.thread.body) {
        return false;
      }
      return !lastThreeCharsOfThreadBody.includes('...');
    },
    isSearchResult() {
      return this.$route.name === 'search';
    },
    summary() {
      let summaryContent = this.thread.body;
      if (this.isSearchResult) {
        summaryContent = `...${summaryContent}`;
      }
      return `${summaryContent}${this.showEllipsis ? '...' : ''}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_thread-teaser';
</style>

<docs>
</docs>
