<template>
  <ul class="tag-list inline-list">
    <li
      v-for="tag in tags"
      :key="tag.name"
      class="tag-list__tag"
    >
      <router-link
        :to="{ name: 'forums', query: { tagId: tag.id } }"
        class="tag-list__tag-link link link--unstyled"
        data-test-id="tag-list-tag-link"
        @click.native="handleTagSelection(tag)"
      >{{ tag.name }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TagList',
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleTagSelection(tag) {
      this.$store.dispatch('setSelectedTags', [tag.id]);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_tag-list';
</style>

<docs>

Display a list of tags, tags are clickable links that show a filtered view of the
forum index.

</docs>
