<template>
  <span v-if="timestamp">Active {{ timestamp }}</span>
</template>

<script>
import { formatDistanceToNow, differenceInDays } from 'date-fns';

export default {
  name: 'RecentActivityTime',
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    daysSinceDate() {
      return differenceInDays(new Date(), new Date(this.date));
    },
    inLastWeek() {
      return this.daysSinceDate <= 7;
    },
    daysAgo() {
      if (this.daysSinceDate < 1) {
        return 'today';
      }
      if (this.daysSinceDate < 2) {
        return 'yesterday';
      }
      return `${formatDistanceToNow(new Date(this.date))} ago`;
    },
    timestamp() {
      if (this.inLastWeek) {
        return this.daysAgo;
      }
      return null;
    },
  },
};
</script>

<docs>
</docs>
