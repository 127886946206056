<template>
  <div class="homepage-engagement">
    <div class="homepage-engagement__grid">
      <div class="homepage-engagement__section" data-test-id="homepage-engagement-stories-section">
        <router-link
          to="/stories"
          class="homepage-engagement__category-link"
          data-test-id="homepage-engagement-stories-link"
        >
          <h2 class="homepage-engagement__category-title">
            Stories
          </h2>
          <icon-component
            name="caret"
            title="caret icon"
            class="homepage-engagement__category-icon"
          />
        </router-link>
        <post-teaser v-if="story" :post="story" />
      </div>
      <div class="homepage-engagement__section" data-test-id="homepage-engagement-forums-section">
        <router-link
          to="/forums"
          class="homepage-engagement__category-link"
          data-test-id="homepage-engagement-forums-link"
        >
          <h2 class="homepage-engagement__category-title">
            Forums
          </h2>
          <icon-component
            name="caret"
            title="caret icon"
            class="homepage-engagement__category-icon"
          />
        </router-link>
        <thread-teaser
          v-if="thread"
          show-bookmark-button
          :thread="thread"
          class="thread-teaser--no-border"
        />
      </div>
    </div>
    <div v-if="engagementType && engagementTool" class="homepage-engagement__grid">
      <div class="homepage-engagement__section homepage-engagement__section--engagement-tool" data-test-id="homepage-engagement-section">
        <client-only v-if="engagementType === 'poll'">
          <poll-component
            :poll-data="engagementTool"
            poll-heading="Featured Poll"
            suppress-img
          />
        </client-only>
        <quizlet-component
          v-else-if="engagementType === 'quizlet'"
          :data="engagementTool"
          suppress-img
        />
        <this-or-that
          v-else-if="engagementType === 'thisOrThat'"
          :data="engagementTool"
          suppress-img
        />
      </div>
      <ad-slot mapping="HomeEngagement" />
    </div>
  </div>
</template>

<script>
import AdSlot from '@/components/Ads/AdSlot.vue';
import PostTeaser from '@/components/Posts/PostTeaser.vue';
import ThreadTeaser from '@/components/Forum/ThreadTeaser.vue';
import PollComponent from '@/components/EngagementToolkit/PollComponent.vue';
import QuizletComponent from '@/components/EngagementToolkit/QuizletComponent.vue';
import ThisOrThat from '@/components/EngagementToolkit/ThisOrThat.vue';

export default {
  name: 'HomepageEngagement',
  components: {
    AdSlot,
    PostTeaser,
    ThreadTeaser,
    PollComponent,
    QuizletComponent,
    ThisOrThat,
  },
  props: {
    story: {
      type: Object,
      default: () => ({}),
    },
    thread: {
      type: Object,
      default: () => ({}),
    },
    engagementTool: {
      type: Object,
      default: null,
    },
    engagementType: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_homepage-engagement';
</style>

<docs>
</docs>
